import { ProduktkarussellModel } from './Models/Produktkarussell';
import { initSwiperLarge } from '../swiper/swiperconfig';
import { CreateSliderContent } from '../Shared/Slider';
import { tracking_eecPromotionview } from '../Tracking/GoogleTracking';
import { mapTrboResponseToProduktkarussellModel } from '../Shared/Trbo/TrboResponseMapper';
import { buildTrboRequestUrl } from '../Shared/Trbo/TrboRequestBuilder';

declare var __ucCmp: any;
const trboId = 'ryxCCtrEX';
const gtmId = 'BJ59EidsWQ';

export class SliderRenderer {
    /**
     * Holt die Produkte der Produktfamilie zu einem Produkt von der Api anhand ihrer Ids.
     * Die Ids werden in einem Hidden-Input zwischengespeichert
     */
    getProductFamily() {
        var productFamilyIds = $('#productFamilyIds').val();
        if (!this.nullOrEmpty(productFamilyIds)) {
            var apiUrl = '/Umbraco/api/ProduktdetailApi/GetProductFamily?productids=' + productFamilyIds;
            $.ajax({
                method: 'GET',
                dataType: 'JSON',
                url: apiUrl,
                success: data => {
                    var products = data as ProduktkarussellModel;
                    
                    if (products?.karussellItems.length === 0) {
                        $('#productFamily_wrapper').addClass('hide');
                        return;
                        
                    }

                    CreateSliderContent(
                        products,
                        $('#productFamilyContainer'),
                        $('#ProduktdetailPrototypes #SliderPrototypes'),
                        'productFamilyContainer',
                        false
                    );
                    //Karussell muss neu initialisiert werden, da der Inhalt bei Document.ready noch nicht verfügbar ist.
                    initSwiperLarge($('#produktkarussellLarge'));
                },
                error: () => {
                    $('.slider-section').addClass('hide');
                },
            });
        }
    }

    getTopProducts() {
        var productFamilyIds = $('#productTopKategorieUmbracoNodeId').val();
        let productId = $('#productTopKategorieProductId').val();
        if (!productId || !productFamilyIds) {
            return;
        }
        if (!this.nullOrEmpty(productFamilyIds)) {
            var apiUrl =
                '/Umbraco/api/ProduktdetailApi/GetRelatedTopProducts?contentId=' +
                productFamilyIds +
                '&productId=' +
                productId;
            $.ajax({
                method: 'GET',
                dataType: 'JSON',
                url: apiUrl,
                success: async data => {
                    var products = data as ProduktkarussellModel;
                    $('.topReihenSlider').removeClass('hidden');
                    CreateSliderContent(
                        products,
                        $('#productTopReihen'),
                        $('#ProduktdetailPrototypes #SliderPrototypes'),
                        'productTopReihen',
                        false
                    );
                    //Karussell muss neu initialisiert werden, da der Inhalt bei Document.ready noch nicht verfügbar ist.
                    let topslider = initSwiperLarge($('#produktkarussellLarge'));

                    topslider.update();
                    setTimeout(function() {
                        // nasty hack aber keinen weg gefunden das karussell auf seite 1 springt...
                        $('.slider .swiper-pagination-bullet')
                            .first()
                            .click();
                    }, 100);
                    await tracking_eecPromotionview();
                },
                error: () => {
                    $('.slider').addClass('hide');
                },
            });
        }
    }

    async getTrboProducts() {
        if (window['__ucCmp'] !== undefined && (await __ucCmp.isInitialized() == false)) {
            setTimeout(this.getTrboProducts.bind(this), 200);
            return;
        }

        const details = await __ucCmp.getConsentDetails();
        const trboAllowed = details.services[trboId].consent.given;
        const gtmAllowed = details.services[gtmId].consent.given;
        const getTrboProducts = trboAllowed && gtmAllowed;

        if (
            getTrboProducts &&
            window['_trbo'] === undefined
        ) {
            setTimeout(this.getTrboProducts.bind(this), 100);
            return;
        } else {
            try {
                if (!getTrboProducts) {
                    $('.trboProductsSlider').addClass('hide');
                    this.getTopProducts();
                    return;
                }
                $('.trboProductsSlider').removeClass('hide');
                const requestUrl = buildTrboRequestUrl('Product_Reco_PDP_s2s');
                $.ajax({
                    method: 'GET',
                    dataType: 'JSON',
                    url: encodeURI(requestUrl),
                    success: async data => {
                        var trboProducts = mapTrboResponseToProduktkarussellModel(data) as ProduktkarussellModel;
                        if (trboProducts.karussellItems.length === 0) {
                            $('.trboProductsSlider').addClass('hide');
                            this.getTopProducts();
                            return;
                        }
                        $('.topReihenSlider').addClass('hidden');
                        CreateSliderContent(
                            trboProducts,
                            $('#productTrbo'),
                            $('#ProduktdetailPrototypes #SliderPrototypes'),
                            'productTrbo',
                            false
                        );
                        //Karussell muss neu initialisiert werden, da der Inhalt bei Document.ready noch nicht verfügbar ist.
                        let trboslider = initSwiperLarge($('#produktkarussellLargeTrbo'));

                        await tracking_eecPromotionview();

                        trboslider.update();
                        setTimeout(function() {
                            // nasty hack aber keinen weg gefunden das karussell auf seite 1 springt...
                            $('.trboProductsSlider .swiper-pagination-bullet')
                                .first()
                                .click();
                        }, 100);
                    },
                    error: () => {
                        $('.trboProductsSlider').addClass('hide');
                        this.getTopProducts();
                    },
                });
            } catch {
                $('.trboProductsSlider').addClass('hide');
            }
        }
    }

    async getTrboLastSeenProducts() {
        if (window['__ucCmp'] === undefined || (await __ucCmp.isInitialized() == false)) {
            setTimeout(this.getTrboLastSeenProducts.bind(this), 200);
            return;
        }
        
        const details = await __ucCmp.getConsentDetails();
        const trboAllowed = details.services[trboId].consent.given;
        const gtmAllowed = details.services[gtmId].consent.given;
        const getTrboProducts = trboAllowed && gtmAllowed;
        

        if (
            getTrboProducts &&
            window['_trbo'] === undefined
        ) {
            setTimeout(this.getTrboLastSeenProducts.bind(this), 100);
            return;
        }

        try {
            if (!getTrboProducts) {
                $('.trboLastSeen').addClass('hide');
                return;
            }
            $('.trboLastSeen').removeClass('hide');
            const requestUrl = buildTrboRequestUrl('Product_Last-Seen_PDP_s2s');
            $.ajax({
                method: 'GET',
                dataType: 'JSON',
                url: encodeURI(requestUrl),
                success: async data => {
                    var trboProducts = mapTrboResponseToProduktkarussellModel(data) as ProduktkarussellModel;
                    if (trboProducts.karussellItems.length > 0) {
                        CreateSliderContent(
                            trboProducts,
                            $('#productTrboLastSeen'),
                            $('#ProduktdetailPrototypes #SliderPrototypes'),
                            'productTrboLastSeen',
                            false
                        );
                        //Karussell muss neu initialisiert werden, da der Inhalt bei Document.ready noch nicht verfügbar ist.
                        let trboslider = initSwiperLarge($('#produktkarussellLargeTrboLastSeen'));
                        await tracking_eecPromotionview();

                        trboslider.update();
                        setTimeout(function() {
                            // nasty hack aber keinen weg gefunden das karussell auf seite 1 springt...
                            $('.trboLastSeen .swiper-pagination-bullet')
                                .first()
                                .click();
                        }, 100);
                    } else {
                        $('.trboLastSeen').addClass('hide');
                    }
                },
                error: () => {
                    $('.trboLastSeen').addClass('hide');
                },
            });
        } catch {}
    }

    private nullOrEmpty(element) {
        return element === null || element === '' || typeof element === 'undefined';
    }
}
